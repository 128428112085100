import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "writing-a-plugin"
    }}>{`Writing a plugin`}</h1>
    <p>{`For a full-fledged introduction to plugins it’s recommended to read the `}<a parentName="p" {...{
        "href": "https://github.com/remarkjs/remark/blob/master/doc/plugins.md#creating-plugins"
      }}>{`remark guides`}</a>{` and study up on `}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/unist#syntax-tree"
      }}>{`syntax trees`}</a>{`.`}</p>
    <p>{`Now let’s consider an example where you want to pass all headings through the `}<a parentName="p" {...{
        "href": "https://github.com/zeit/title"
      }}>{`title`}</a>{` module to ensure consistent capitalization.  We can use `}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/unist-util-visit"
      }}>{`unist-util-visit`}</a>{` to visit all headings and change the text nodes with `}<inlineCode parentName="p">{`title(text)`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "visiting-heading-nodes"
    }}>{`Visiting heading nodes`}</h2>
    <p>{`The first thing you want to do is install the `}<inlineCode parentName="p">{`unist-util-visit`}</inlineCode>{` library.  This is a utility library that allows you
to visit all heading (or any other type of nodes) without having to write a lot of boiler plate code.  It handles that
for you.`}</p>
    <p>{`Then you can first log out the nodes to see it in action:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const visit = require('unist-util-visit')

module.exports = () => (tree, file) => {
  visit(tree, 'heading', node => {
    console.log(node)
  })
}
`}</code></pre>
    <p>{`This will log out all the nodes in your document that are headings.  Inside heading nodes there are text nodes.  These
include the raw text included in the heading.`}</p>
    <p><em parentName="p">{`Note`}</em>{`: The reason that heading nodes include multiple text node types is because there can be other
“`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/2011/WD-html5-author-20110809/content-models.html#phrasing-content-0"
      }}>{`phrasing content`}</a>{`” nodes.  For example if your heading looked like `}<inlineCode parentName="p">{`# Hello, _world_`}</inlineCode>{`.  In addition to the
text there is also an emphasis node.`}</p>
    <h2 {...{
      "id": "visiting-text-nodes"
    }}>{`Visiting text nodes`}</h2>
    <p>{`To walk the text nodes, you can use `}<inlineCode parentName="p">{`unist-util-visit`}</inlineCode>{` again:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const visit = require('unist-util-visit')

module.exports = () => (tree, file) => {
  visit(tree, 'heading', node => {
    visit(node, 'text', textNode => {
      console.log(textNode)
    })
  })
}
`}</code></pre>
    <h2 {...{
      "id": "using-the-title-library"
    }}>{`Using the title library`}</h2>
    <p>{`Now that you are logging out the text nodes, you can now manipulate them with the title library by reassigning tthe value
to the node:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const title = require('title')
const visit = require('unist-util-visit')

module.exports = () => (tree, file) => {
  visit(tree, 'heading', node => {
    visit(node, 'text', textNode => {
      const text = textNode.value ? textNode.value.trim() : ''  
      textNode.value = title(text)
    })
  })
}
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      